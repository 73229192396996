ol.levels-list {
  margin-left: 2em;
  counter-reset: list-item -1;
  li{
    margin-bottom: 1em;
    &::marker {
    content: 'Level ' counter(list-item) ': ';
    font-family: 'Josefin Sans', sans-serif;;
    font-weight: 400;
    font-size: 1.2em;
  }}
}
