@use 'Vars.scss' as *;

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Raleway:wght@200;400;700&display=swap');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.title {
  color: $white;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
}

.subtitle {
  color: $primary;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
}

section h2.section-title {
  text-align: center;
  border-bottom: solid 1px #ffffff77;
  border-top: solid 1px #ffffff77;
  width: 100%;
  margin: 0 auto;
  padding: 0.4em 0;
}

h1.hd-seo {
  display: none;
}

.text {
  color: $white;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}
p .small {
  opacity: 0.7;
  font-size: 0.8em;
}

.label {
  color: $white;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
}

.space {
  flex: 1 1;
}

body {
  background-color: $bkg;
}

.App {
  background-color: $bkg;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
  font-size: calc(10px + 2vmin);
  color: $white;
}

.App section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.App .content {
  padding: 1em;
}

@keyframes in-keyframes {
  0% {
    opacity: 0;
    translate: 0 -100%;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

.animate-in {
  animation-name: in-keyframes;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.dialog-info {
  .title {
    font-size: 1.4em;
    color: $bkg;
    margin-bottom: 0;
    svg {
      path {
        fill: $bkg;
      }
    }
  }

  .subtitle {
    font-size: 1.2em;
    margin-top: 0;
  }
}
