@use './../Vars.scss' as *;

.About {
  .description {
    margin: 0 2em;
    flex: 11 1 40%;
    a {
      color: $primary;
    }
  }
  .profile {
    margin: 2em;
    flex: 1 1 40%;
    max-height: 100vh;
    .profile_photo {
      flex: 1 1 100%;
      max-width: 70%;
      aspect-ratio: 2/3;
      height: auto;
      border-radius: 50%/30%;
    }
  }
}

@media screen and (max-width: $breakpoint-small) {
  .About {
    .content {
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      .profile {
        margin: 0.6em;
        .profile_photo {
          flex-grow: 2;
          max-width: 60%;
        }
      }
    }
  }
}
