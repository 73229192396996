@use './../Vars.scss' as *;

.Project {
    .ProjectHeader {
        padding: 2em;
        position: relative;

        .projectBanner {
            position: absolute;
            top: 0;
            right: 0;
            opacity: .3;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .MuiStack-root {
            position: relative;
        }
    }

    .ProjectContent {
        flex-grow: 1;
        padding: 2em;
        background-color: $white;
        color: $bkg;
        font-size: 1em;

        .textContent {
            max-width: 45em;
            margin: auto;

            h3 {
                margin: 0;
            }

            p {
                margin-top: .6em;
            }
        }
    }
}

.ligthbox-img-container {
    height: 100vh;

    .ligthbox-img {
        flex: 1 1;
        max-height: 90vh;
        max-width: 90vw;
        object-fit: contain;
    }
}