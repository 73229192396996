@use './../Vars.scss' as *;

.Contact {
  .content {
    margin: 2em;

    .form {
      min-width: 40%;

      .MuiFormLabel-root {
        color: #ffffffcc;
      }

      .MuiOutlinedInput-root {
        color: white;
      }

      .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
        border-color: #ffffffcc;
      }

      .send-button-container {
        button {
          width: 100%;
          &:disabled{
            color:#ffffff88;
          }
        }

        p {
          visibility: hidden;
          font-size: .6em;
          opacity: .7;
        }

        &:hover p {
          visibility: initial;

        }

      }
    }
  }
}

@media screen and (max-width: $breakpoint-small) {
  .Contact {
    .content {
      flex-direction: column !important;
      margin: 0.6em;

      .form {
        min-width: 90%;
        margin-bottom: 1em;
      }

      .contact-info {
        flex-direction: row;
        margin: 0;

        .contact-link {
          margin: .6em;
        }
      }
    }
  }
}