@use '../../Vars.scss' as *;

.Nav {
  display: flex;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
  padding: 0 1em;
  box-sizing: border-box;
  .select-lang {
    display: none;
    /* TODO Corregir lenguajes */
  }
}

/* wave link styles */
body .react-bubbly-transitions__bubbly-link {
  margin: 1em;
  padding: 0;
  outline: none;
}

body .react-bubbly-transitions__bubbly-link.active {
  position: relative;
  color: $primary;
}

body .react-bubbly-transitions__bubbly-link.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background: $primary;
}
