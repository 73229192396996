@use './../Vars.scss' as *;

.Home {

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h3 {
    font-size: 1.4em;
  }

  .name {
    font-size: 2.2em;
  }

  .degree {
    margin-top: 2em;
    font-size: 1em;
    min-width: 10em;
    cursor: pointer;
  }
}

@media screen and (max-width: $breakpoint-small) {
  .Home h3 {
    font-size: 1.1em;
  }

  .Home .name {
    font-size: 1.6em;
  }

  .Home .degree {
    margin-top: 2.6em;
  }
}
