@use './../Vars.scss' as *;

.Projects {
    .ProjectList {
        height: 100%;
        overflow-y: auto;
        background-color: $white;
        display: grid;
        gap: 2em;
        padding: 2em;
        grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));

        .react-bubbly-transitions__bubbly-link {
            margin: 0;
        }

        .ProjectItem {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            aspect-ratio: 1.5;

            .ProjectCover {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .ProjectContent {

                width: 100%;
                height: 100%;
                background-color: #0D1E3077; // $bkg;
                position: relative;
                box-sizing: border-box;
                padding: 1em 2em;

                .title {
                    font-size: 2em;
                    text-align: left;
                }
            }
        }
    }
}