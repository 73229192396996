@use '../../Vars.scss' as *;

.profile_icon {
    width: 3em;
    height: 3em;
    object-fit: cover;
    border-radius: 50%;
  }
.contact-link {
  text-decoration: none;
  text-align: center;
  margin-bottom: 1em;
  display: block;
  .contact-img {
    background-color: $white;
    color: $bkg;
    margin: auto;
  }
  .contact-link-content {
    color: $white;
    font-size: 0.6em;
  }

  &:hover {
    .contact-img {
        background-color: $primary;
      color: $white;
    }
    .contact-link-content {
      color: $primary;
    }
  }
}
