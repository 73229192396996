@use '../../Vars.scss' as *;

.ref-Container {
  width: 100%;
  height: 100%;
  flex: 1 1;
  border-top: solid 1px white;
}

.Skills .skills-proficiency {
  align-self: flex-end;
  margin: 0.4em 1em 0;

  font-family: 'Raleway', sans-serif;
  font-weight: 200;
}
.skill_map svg {
  overflow: visible;
}

.deg,
.skill {
  cursor: pointer;
  overflow: visible;
  user-select: none;
}

.shape-hover {
  stroke-linecap: round;
  stroke-dasharray: 20, 20;
  stroke: $primary;
  // display: none;
  transform: rotate(45deg) scale(0.8);
  transition: transform 0.2s;
}

.hover .shape-hover {
  transform: rotate(45deg) scale(1);
  animation: dash 2s linear;
  animation-iteration-count: infinite;
}

.skill.hover .shape,
.skill.hover .logo,
.deg.hover .shape,
.deg.hover .title,
.deg.hover .subtitle,
.deg.hover .logo,
.skill.hover .shape-hover,
.deg.hover .shape-hover {
  stroke-opacity: initial;
  opacity: initial;
  display: initial;
}

.skill.hover .shape,
.skill.hover .level-shape,
.deg.hover .shape {
  fill: #c9d5e4;
  stroke: #0d1e30;
}

.skill .logo,
.deg .logo {
  fill: #c9d5e4;
  path {
    fill: #c9d5e4;
  }
}
.skill.hover .logo,
.deg.hover .title,
.deg.hover .subtitle,
.deg.hover .logo {
  font-weight: bolder;
  fill: #0d1e30;
  path {
    fill: #0d1e30;
  }
}

.skill {
  .shape {
    stroke-opacity: 0.5;
  }

  .logo {
    opacity: 0.5;
  }
}

.deg .shape {
  // stroke-opacity: 0.8;
}

.deg .title,
.deg .subtitle,
.deg .logo,
.skill .title,
.skill .level-text {
  // opacity: 0.7;
  font-family: 'Raleway', sans-serif;
  text-anchor: middle;
  font-weight: 200;
}

.deg {
  .title {
    font-size: 40px;
  }
  .subtitle {
    font-size: 17px;
  }
}

.skill {
  .title {
    font-size: 40px;
  }
  .level-text {
    font-size: 36px;
    font-weight: 400;
  }
}

.dialog-skill svg .title {
  font-size: 50px;
  font-family: 'Raleway', sans-serif;
  text-anchor: middle;
  font-weight: 200;
}

.dialog-skill .level-subtitle {
  font-weight: 400;
  margin: 0.6em auto;
  font-size: 0.8em;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  strong {
    margin-right: 0.6em;
    flex-shrink: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 200;
  }
}
